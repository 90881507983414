<template>
  <b-card>
    <b-row>
      <b-col>
        <div>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <download-excel
              class="btn btn-primary pull-right ml-3"
              :fetch="fetchData"
              :fields="download.header"
              :before-generate="startDownload"
              :before-finish="finishDownload"
              :name="'Зээлийн түүх.xls'"
              v-if="items != undefined && items.length > 0"
            >
              <b-spinner
                small
                type="grow"
                v-show="download.loading"
              ></b-spinner>
              <span v-show="download.loading" class="ml-1">Уншиж байна</span>
              <i class="fa fa-download" v-show="!download.loading"></i>
              <span v-show="!download.loading" class="ml-1">Excel татах</span>
            </download-excel>

            <div class="pull-right">
              <b-form-select
                v-model="query.per_page"
                :options="[5, 10, 25, 100]"
                v-if="items != undefined && items.length > 0"
              >
              </b-form-select>
            </div>
            <b-button variant="primary" @click="showFilter()"
              >Шүүлт
              <i
                :class="{
                  'fa fa-caret-up': filterShow,
                  'fa fa-caret-down': !filterShow,
                }"
              ></i
            ></b-button>
          </div>
          <b-card v-show="filterShow" class="mb-3">
            <b-form @submit.stop.prevent="submitSearch">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Амжилттай болсон огноо">
                    <b-input-group>
                      <date-picker
                        v-model="filter.startDate"
                        :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                        placeholder="Эхлэх огноо"
                      ></date-picker>
                      <date-picker
                        v-model="filter.endDate"
                        :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                        placeholder="Дуусах огноо"
                      ></date-picker>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Ажилтан" label-for="operator">
                    <b-select v-model="filter.operatorId">
                      <option :value="null">Бүгд</option>
                      <option
                        :value="item.id"
                        :key="index"
                        v-for="(item, index) in operators"
                        >@{{ item.firstname }}</option
                      >
                    </b-select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label="Гэрээний ажилтан"
                    label-for="loanOfficers"
                  >
                    <b-select v-model="filter.loanOfficerId">
                      <option :value="null">Бүгд</option>
                      <option
                        :value="item.id"
                        :key="index"
                        v-for="(item, index) in loanOfficers"
                        >@{{ item.firstname }}</option
                      >
                    </b-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button
                    variant="primary"
                    @click="submitSearch()"
                    style="margin-top: 28px;"
                    >Хайх</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-card>
          <div
            class="table-responsive"
            v-if="items != undefined && items.length > 0"
          >
            <b-table
              id="filter-table"
              stacked="md"
              @sort-changed="sortingChanged"
              hover
              bordered
              small
              responsive="sm"
              :items="items"
              :fields="columns"
              no-local-sorting
            >
              <template v-slot:cell(index)="data">
                {{ data.index + pagination.from }}
              </template>
              <template v-slot:cell(loanOfficerStateName)="data">
                <b-badge
                  variant="info"
                  v-if="data.item.hasOwnProperty('loanOfficerStateName')"
                  >{{ data.item.loanOfficerStateName }}</b-badge
                >
                <b-badge variant="danger" v-else>Төлөв сонгоогүй</b-badge>
              </template>
              <template v-slot:cell(loanOfficerName)="data">
                <strong class="text-blue"
                  >{{ data.item.loanOfficerName != null ? "@" : ""
                  }}{{ data.item.loanOfficerName }}</strong
                >
              </template>
              <template v-slot:cell(operatorName)="data">
                <strong class="text-blue"
                  >{{ data.item.operatorName != null ? "@" : ""
                  }}{{ data.item.operatorName }}</strong
                >
              </template>
            </b-table>

            <div slot="footer" class="mb-0 mt-3">
              <nav class="pull-right" v-if="pagination.total > query.per_page">
                <b-pagination
                  class="mb-0"
                  :total-rows="pagination.total"
                  :per-page="query.per_page"
                  v-model="query.page"
                />
              </nav>
              <p class="mb-0">
                {{ pagination.total }} өгөгдлөөс
                {{ pagination.to ? pagination.from : 0 }} -
                {{ pagination.to }} өгөгдөл харагдаж байна.
              </p>
            </div>
          </div>

          <b-alert variant="warning" v-else show class="mb-0">
            Мэдээлэл байхгүй байна
          </b-alert>
        </div>

        <create
          :sales="selectedSales"
          :callback="loadData"
          :states="states"
          :hideCallback="modalReset"
        ></create>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";

import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "sales-new-list",
  components: { axios, datePicker },
  props: ["customerId"],
  data: function() {
    return {
      isLoading: false,
      filterShow: false,
      selectedSales: null,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: "ASC",
        orderBy: "registered",
        filter: "{}",
      },
      filter: {
        startDate: "",
        endDate: "",
        loanOfficerId: null,
        operatorId: null,
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      operators: [],
      loanOfficers: [],
      states: [],
      search: "",
      download: {
        loading: false,
        header: {},
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Овог",
          key: "lastname",
          sortable: true,
          sort_key: "lastname",
        },
        {
          label: "Нэр",
          key: "firstname",
          sortable: true,
          sort_key: "firstname",
        },
        {
          label: "Регистр",
          key: "register",
          sortable: true,
          sort_key: "register",
        },
        { label: "Утас", key: "phone", sortable: true, sort_key: "phone" },
        { label: "Товлосон огноо", key: "posDate" },
        { label: "Товлосон хаяг", key: "posAddress" },
        {
          label: "Товлосон ажилтан",
          key: "operatorName",
          sortable: true,
          sort_key: "operator",
        },
        {
          label: "Гэрээний ажилтан",
          key: "loanOfficerName",
          sortable: true,
          sort_key: "loanOfficer",
        },
        {
          label: "Амжилттай болсон огноо",
          key: "loanOfficerUpdatedDate",
          sortable: true,
          sort_key: "registered",
        },
      ],
      isActiveLoan: false,
    };
  },
  created: function() {
    this.initData();
    this.$data.download.header = {
      "№": "index",
      Овог: "lastname",
      Нэр: "firstname",
      Регистр: "register",
      Утас: "phone",
      "Товлосон огноо": "posDate",
      "Товлосон хаяг": "posAddress",
      "Товлосон ажилтан": "operatorName",
      "Гэрээний ажилтан": "loanOfficerName",
      "Амжилттай болсон огноо": "loanOfficerUpdatedDate",
    };
    this.loadData();
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    initData: function() {
      this.$http
        .get(this.$config.API_URL + "SalesWebService/get_loan_officer_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response)
            this.$data.loanOfficers = response.body.data;
          },
          (response) => {}
        );

      this.$http
        .get(this.$config.API_URL + "SalesWebService/get_operator_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response)
            this.$data.operators = response.body.data;
          },
          (response) => {}
        );
    },
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "SalesWebService/get_customer_contracted_list",
          {
            params: this.$data.query,
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.items = response.body.data;
            this.$data.pagination.total = response.body.total;
            this.$data.pagination.from = response.body.from;
            this.$data.pagination.to = response.body.to;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
      this.loadData();
    },
    submitSearch: function() {
      this.$router.replace({ name: this.$route.name, query: this.$data.query });
      this.$data.query.filter = JSON.stringify(this.$data.filter);
      this.$data.page = 1;
      this.loadData();
    },
    async fetchData() {
      if (this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(
          this.$config.API_URL + "SalesWebService/get_customer_contracted_list",
          {
            params: {
              page: 1,
              per_page: 0,
              orderDirection: this.$data.query.orderDirection,
              orderBy: this.$data.query.orderBy,
              filter: this.$data.query.filter,
              id: this.$data.query.id,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        );
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.download.loading = false;
          return [];
        }

        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },
    showFilter() {
      this.$data.filterShow = !this.$data.filterShow;
    },
    modalReset: function() {
      this.$data.selectedSales = null;
    },
    showInfo: function(_item) {
      this.$data.selectedSales = _item;
    },
  },
};
</script>
